export type EnvironmentFn = (hostname?: string) => boolean;

export const isProd: EnvironmentFn = (hostname = window.location.host) => {
  const prodHosts = [
    "stedi.com",
    "www.stedi.com",
    "portal.stedi.com",
    "terminal.stedi.com",
    "prod.terminal.stedi.com",
    "app.prod.terminal.stedi.com",
  ];

  return prodHosts.some((prodHost) => hostname === prodHost);
};

export const isPreprod: EnvironmentFn = (hostname = window.location.host) => {
  const preprodHosts = ["preview.stedi.com", "preview.portal.stedi.com"];

  return preprodHosts.some((preprodHost) => hostname === preprodHost);
};

export const isTest = () => process.env["NODE_ENV"] === "test";
